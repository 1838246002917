import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import blog_image from "../../images/blog-blocks-wallpaper-1591056-2.jpg";
import Last10Js from "../../components/last-10-js";
import Last10Elixir from "../../components/last-10-elixir";
import UsefulLinks from "../../components/useful-links";
import CategoriesList from "../../components/available-cats-list";
import moment from "moment";
import { transform } from "lodash";
import ArchiveLink from "../../components/archive-link";

const DisplayMonth = ({ month, posts }) => {
  return (
    <div className="flex flex-col">
      <div className="w-full md:w-1/4">{month}</div>
      <div className="w-full md:w-3/4">
        {posts.map(({ title, path }) => (
          <p className="my-2">
            <Link
              to={path}
              className="font-title text-red-700 hover:text-red-500"
            >
              {title}
            </Link>
          </p>
        ))}
      </div>
    </div>
  );
};

const DisplayYear = ({ year, monthList }) => {
  return (
    <div className="w-full flex p-4 mt-4 border-b">
      <div className="w-1/5 md:w-1/12">{year}</div>
      <div className="w-4/5 md:w-11/12">
        {Object.entries(monthList).map(([month, posts]) => (
          <DisplayMonth month={month} posts={posts}></DisplayMonth>
        ))}
      </div>
    </div>
  );
};

const Archive = ({ data }) => {
  const list = data.allMarkdownRemark.edges;

  const archive = transform(
    list,
    (result, value) => {
      const {
        frontmatter: { path, title, date },
      } = value.node;
      const d = moment(date);
      const year = d.year();
      const month = d.format("MMMM");

      if (result[year]) {
        if (result[year][month]) {
          result[year][month].push({ title, path });
        } else {
          result[year][month] = [];
          result[year][month].push({ title, path });
        }
      } else {
        result[year] = {};
        result[year][month] = [];
        result[year][month].push({ title, path });
      }
    },
    {}
  );

  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Blog" />
      <div
        className="min-h-screen pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 mb-20 bg-contain bg-right-top bg-no-repeat flex flex-col items-center"
        style={{ backgroundImage: `url(${blog_image})` }}
      >
        <div className="container md:mt-20 font-title self-start">
          <div className="w-full mt-6 md:mt-24 md:mt-48"></div>
          <div className="w-full flex flex-wrap pt-4 pb-4 bg-gray-50">
            <div className="w-full md:w-3/4 px-4">
              <h1 className="text-3xl font-title font-bold">Archive</h1>
              {Object.entries(archive)
                .reverse()
                .map(([year, list], i, a) => (
                  <DisplayYear year={year} monthList={list}></DisplayYear>
                ))}
            </div>
            <div className="w-full md:w-1/4 px-4">
              <div className="mb-6">
                <Last10Elixir />
              </div>
              <div className="mb-6">
                <Last10Js />
              </div>
              <div className="mb-6">
                <UsefulLinks />
              </div>
              <div className="mb-6">
                <CategoriesList />
              </div>
              <div className="mb-6">
                <ArchiveLink />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AllPostsQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/blog/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            date
          }
        }
      }
    }
  }
`;
export default Archive;
